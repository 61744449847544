// TYPE HEAD - SUGGESTIVE SEARCH
$(document).ready(function() {

$('input.typehead').typeahead({
		name: 'city',
		remote: 'suggest.php?query=%QUERY'
	},
		{
			hint: true,
			hightlight: true
	});
});

// BURGER ICON
function updateMenuButton() {
	$('.burger').find('.burger-icon').toggleClass('is-active');
}

$(document).ready(function() {
	$('.burger').click(function(e){
		e.preventDefault();
		updateMenuButton();

		// $(".nav").toggleClass("show");

		$(".nav").slideToggle(300).toggleClass("show")

		$(this).toggleClass("close");
	});
	$('.mob--search').click(function(e){
		e.preventDefault();
		$('.nav--search').slideToggle(300).toggleClass('show')
	})
});

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('div').attr('style', 'display:none;').slideDown(300): checkbox.siblings('div').attr('style', 'display:block;').slideUp(300);
				( checkbox.prop('checked') ) ? checkbox.siblings('label').addClass('active'): checkbox.siblings('label').removeClass('active');
			});
		});
	}
});
